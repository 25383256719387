import * as THREE from "three";

import BaseRoom from "./base_room.js";

export default class Room extends BaseRoom {
  config = {
    debug: false,
    title: "Kinderzimmer",
    model: "room2/model.glb",
    hidden_meshes_on_load: [
      "Decke",
      "Lueftung",
      "Wand_re_cut",
      "Wand_li_cut",
      "Fenster_li",
    ],
    camera: {
      position: [3.6391578965719744, 4.3630323237251485, 4.993508609312392],
      quaternion: [
        -0.25621174775630945, 0.28726101795812076, 0.08004552747933844,
        0.9194723276988144,
      ],
      lookAt: [0.023591703584522415, 0.525539312143915, -0.2281026637702244],
    },
    controls: {
      maxAzimuthAngle: Math.PI,
      minAzimuthAngle: Math.PI * 0.5,
      maxPolarAngle: Math.PI * 0.5,
      maxDistance: 12,
    },
    textures: {
      one_one: {
        file: "room2/lightmap.jpg",
        opts: {
          flipY: false,
          colorSpace: THREE.SRGBColorSpace,
          side: THREE.DoubleSide,
        },
      },
      zero_one: {
        file: "room2/lightmap-right.jpg",
        opts: {
          flipY: false,
          colorSpace: THREE.SRGBColorSpace,
          side: THREE.DoubleSide,
        },
      },
      one_zero: {
        file: "room2/lightmap-left.jpg",
        opts: {
          flipY: false,
          colorSpace: THREE.SRGBColorSpace,
          side: THREE.DoubleSide,
        },
      },
      zero_zero: {
        file: "room2/lightmap-dark.jpg",
        opts: {
          flipY: false,
          colorSpace: THREE.SRGBColorSpace,
          side: THREE.DoubleSide,
        },
      },
    },
    default_materials: {},
  };
  materials = {};
  group = new THREE.Group();
  slots_meshes = {
    floor: ["Boden"],
    floor_boards: ["Fussleiste_re", "Fussleiste_li"],
    walls: ["Wand_re_cut", "Wand_li_cut", "Wand_li", "Wand_re"],
    window_left_blinds: ["rollo_li"],
    window_right_blinds: ["rollo_re"],
    window_left: ["Fenster_li"],
    window_right: ["Velux"],
    extras: ["Lueftung", "rollo_li", "rollo_re"],
  };
  slots_components = {
    floor: 0,
    floor_boards: 0,
    walls: 0,
  };
  component_names = {
    window_left: "Giebelfenster",
    window_right: "Dachschrägenfenster",
    window_left_blinds: "Giebelfenster-Zubehör",
    window_right_blinds: "Dachschrägenfenster-Zubehör",
    floor: "Bodenbelag",
    floor_boards: "Fußleisten",
    walls: "Wandfarbe",
    interior_walls: "Innenmauerwerk",
    sloping_roof: "Dachschräge",
    heating: "Heizung",
    ventilation: "Lüftung",
    smart_home: "Smart-Home",
    app: "Bauherren-App",
    solar: "PV-Anlage",
  };
  components = {
    window_left: [
      {
        title: "Fenster",
        manufacturer: "",
        attributes: {
          daylight: 30,
          humidity: -10,
          temperature: 6,
        },
        image: "/fenster.png",
        onSelect: () => {
          let children_to_show = ["Fenster_li", "Wand_li_cut"];
          let children_to_hide = ["Wand_li"];

          this.group.children[0].children.forEach((child) => {
            if (children_to_show.includes(child.name)) {
              child.visible = true;
            } else if (children_to_hide.includes(child.name)) {
              child.visible = false;
            }

            if (
              !this.slots_components["window_left_blinds"] &&
              this.slots_meshes["window_left_blinds"].includes(child.name)
            ) {
              const skyTexture = this.textureLoader.load("sky.png");
              skyTexture.flipY = false;
              skyTexture.offset.set(0.47, 0);
              skyTexture.colorSpace = THREE.SRGBColorSpace;

              this.leftBlindsMaterial = new THREE.MeshStandardMaterial({
                roughness: 0.7,
                emissive: new THREE.Color(0x87ceeb),
                emissiveMap: skyTexture,
                envMapRotation: (0, 1, 1),
                emissiveIntensity: 1,
              });
              child.material = this.leftBlindsMaterial;
            }
          });
        },
        onUnselect: () => {
          let children_to_show = ["Wand_li"];
          let children_to_hide = ["Fenster_li", "Wand_li_cut"];

          this.group.children[0].children.forEach((child) => {
            if (children_to_show.includes(child.name)) {
              child.visible = true;
            } else if (children_to_hide.includes(child.name)) {
              child.visible = false;
            }
          });

          this.unSelectComponent("window_left_blinds");
          this.unSelectComponent("window_left_aircon");
        },
      },
    ],
    window_right: [
      {
        title: "Dachfenster",
        manufacturer: "Velux",
        attributes: {
          daylight: 30,
          humidity: -10,
          temperature: 6,
        },
        image: "/dachfenster.png",
        onSelect: () => {
          let children_to_show = [
            "Velux",
            "stresni_okna_plechy003",
            "Wand_re_cut",
          ];
          let children_to_hide = ["Wand_re"];

          this.group.children[0].children.forEach((child) => {
            if (children_to_show.includes(child.name)) {
              child.visible = true;
            } else if (children_to_hide.includes(child.name)) {
              child.visible = false;
            }

            if (
              !this.slots_components["window_right_blinds"] &&
              this.slots_meshes["window_right_blinds"].includes(child.name)
            ) {
              const skyTexture = this.textureLoader.load("sky.png");
              skyTexture.flipY = false;
              skyTexture.offset.set(0.47, 0);
              skyTexture.colorSpace = THREE.SRGBColorSpace;

              this.rightBlindsMaterial = new THREE.MeshStandardMaterial({
                roughness: 0.7,
                emissive: new THREE.Color(0x87ceeb),
                emissiveMap: skyTexture,
                envMapRotation: (0, 1, 1),
                emissiveIntensity: 1,
              });
              child.material = this.rightBlindsMaterial;
            }
          });
        },
        onUnselect: () => {
          let children_to_show = ["Wand_re"];
          let children_to_hide = [
            "Velux",
            "stresni_okna_plechy003",
            "Wand_re_cut",
          ];

          this.group.children[0].children.forEach((child) => {
            if (children_to_show.includes(child.name)) {
              child.visible = true;
            } else if (children_to_hide.includes(child.name)) {
              child.visible = false;
            }
          });
          delete this.slots_components["window_right_blinds"];
        },
      },
    ],
    window_left_blinds: [
      {
        title: "Rolladen für li. Fenster",
        manufacturer: "",
        attributes: {
          temperature: -3,
        },
        image: "/mtex_med_18097.png",
        onSelect: () => {
          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["window_left_blinds"].includes(child.name)) {
              const blindTexture =
                this.textureLoader.load("mtex_med_18097.png");
              blindTexture.flipY = false;
              blindTexture.colorSpace = THREE.SRGBColorSpace;
              blindTexture.repeat.set(2, 10);
              blindTexture.wrapS = THREE.RepeatWrapping;
              blindTexture.wrapT = THREE.RepeatWrapping;

              this.leftBlindsMaterial = new THREE.MeshStandardMaterial({
                map: blindTexture,
                roughness: 0.7,
                emissive: new THREE.Color(0x87ceeb),
                emissiveMap: blindTexture,
                emissiveIntensity: 0.4,
              });
              child.material = this.leftBlindsMaterial;
            }

            if (["Wand_li_cut"].includes(child.name)) {
              child.material = child.material.clone();
              child.material.lightMap = this.getLightMapMaterial("1?").map;
            }
            if (["Fenster_li"].includes(child.name)) {
              child.material = child.material.clone();
              child.material = this.getLightMapMaterial("11");
            }
          });
        },
        onUnselect: () => {
          delete this.slots_components["window_left_blinds"];
        },
      },
    ],
    window_left_aircon: [],
    window_right_blinds: [
      {
        title: "Dachfenstermarkise",
        manufacturer: "Velux",
        attributes: {
          temperature: -2,
        },
        image: "/mtex_med_20888.jpg",
        onSelect: () => {
          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["window_right_blinds"].includes(child.name)) {
              const blindTexture =
                this.textureLoader.load("mtex_med_20888.jpg");
              blindTexture.flipY = false;
              blindTexture.colorSpace = THREE.SRGBColorSpace;
              blindTexture.repeat.set(2, 10);
              blindTexture.wrapS = THREE.RepeatWrapping;
              blindTexture.wrapT = THREE.RepeatWrapping;

              this.leftBlindsMaterial = new THREE.MeshStandardMaterial({
                map: blindTexture,
                roughness: 0.7,
                emissive: new THREE.Color(0x87ceeb),
                emissiveMap: blindTexture,
                emissiveIntensity: 1.2,
              });
              child.material = this.leftBlindsMaterial;
            }

            if (["Wand_re_cut"].includes(child.name)) {
              child.material = child.material.clone();
              child.material.lightMap = this.getLightMapMaterial("?1").map;
            }
            if (["Velux"].includes(child.name)) {
              child.material = child.material.clone();
              child.material = this.getLightMapMaterial("11");
            }
          });
        },
        onUnselect: () => {
          delete this.slots_components["window_right_blinds"];
        },
      },
    ],
    floor: [
      {
        // Eiche Crema lebhaft (87902)
        title: "Parkett Eiche Crema, lebhaft",
        manufacturer: "bauwerk",
        attributes: {
          sound: -10,
        },
        image: "./floors/mtex_med_87902.jpg",
        onSelect: () => {
          const laminateFloorTexture = this.textureLoader.load(
            "floors/mtex_med_87902.jpg",
          );
          laminateFloorTexture.flipY = false;
          laminateFloorTexture.colorSpace = THREE.SRGBColorSpace;
          laminateFloorTexture.wrapS = THREE.RepeatWrapping;
          laminateFloorTexture.wrapT = THREE.RepeatWrapping;
          laminateFloorTexture.repeat.set(3, 3);

          this.floorMaterial = new THREE.MeshStandardMaterial({
            map: laminateFloorTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 6,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        // Eiche Avoria, lebhaft (87982)
        title: "Parkett Eiche Avoria, lebhaft",
        manufacturer: "bauwerk",
        attributes: {
          sound: -10,
        },
        image: "./floors/mtex_med_87982.jpg",
        onSelect: () => {
          const laminateFloorTexture = this.textureLoader.load(
            "floors/mtex_med_87982.jpg",
          );
          laminateFloorTexture.flipY = false;
          laminateFloorTexture.colorSpace = THREE.SRGBColorSpace;
          laminateFloorTexture.wrapS = THREE.RepeatWrapping;
          laminateFloorTexture.wrapT = THREE.RepeatWrapping;
          laminateFloorTexture.repeat.set(3, 3);

          this.floorMaterial = new THREE.MeshStandardMaterial({
            map: laminateFloorTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 8,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        // Eiche, sehr ruhig (88045)
        title: "Parkett Eiche, sehr ruhig",
        manufacturer: "bauwerk",
        attributes: {
          sound: -10,
        },
        image: "./floors/mtex_med_88045.jpg",
        onSelect: () => {
          const laminateFloorTexture = this.textureLoader.load(
            "floors/mtex_med_88045.jpg",
          );
          laminateFloorTexture.flipY = false;
          laminateFloorTexture.colorSpace = THREE.SRGBColorSpace;
          laminateFloorTexture.wrapS = THREE.RepeatWrapping;
          laminateFloorTexture.wrapT = THREE.RepeatWrapping;
          laminateFloorTexture.repeat.set(3, 3);

          this.floorMaterial = new THREE.MeshStandardMaterial({
            map: laminateFloorTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 6,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        // Eiche rustikal (87166)
        title: "Parkett Eiche rustikal",
        manufacturer: "bauwerk",
        attributes: {
          sound: -10,
        },
        image: "./floors/mtex_med_87166.jpg",
        onSelect: () => {
          const laminateFloorTexture = this.textureLoader.load(
            "floors/mtex_med_87166.jpg",
          );
          laminateFloorTexture.flipY = false;
          laminateFloorTexture.colorSpace = THREE.SRGBColorSpace;
          laminateFloorTexture.wrapS = THREE.RepeatWrapping;
          laminateFloorTexture.wrapT = THREE.RepeatWrapping;
          laminateFloorTexture.repeat.set(3, 3);

          this.floorMaterial = new THREE.MeshStandardMaterial({
            map: laminateFloorTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 8,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        // Eiche Mandorla, rustikal (87928)
        title: "Parkett Eiche Mandorla, rustikal",
        manufacturer: "",
        attributes: {
          sound: -10,
        },
        image: "./floors/mtex_med_87928.jpg",
        onSelect: () => {
          const laminateFloorTexture = this.textureLoader.load(
            "floors/mtex_med_87928.jpg",
          );
          laminateFloorTexture.flipY = false;
          laminateFloorTexture.colorSpace = THREE.SRGBColorSpace;
          laminateFloorTexture.wrapS = THREE.RepeatWrapping;
          laminateFloorTexture.wrapT = THREE.RepeatWrapping;
          laminateFloorTexture.repeat.set(3, 3);

          this.floorMaterial = new THREE.MeshStandardMaterial({
            map: laminateFloorTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 12,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        // Eiche geräuchert, ruhig (87997)
        title: "Parkett Eiche geräuchert, ruhig",
        manufacturer: "",
        attributes: {
          sound: -10,
        },
        image: "./floors/mtex_med_87997.jpg",
        onSelect: () => {
          const laminateFloorTexture = this.textureLoader.load(
            "floors/mtex_med_87997.jpg",
          );
          laminateFloorTexture.flipY = false;
          laminateFloorTexture.colorSpace = THREE.SRGBColorSpace;
          laminateFloorTexture.wrapS = THREE.RepeatWrapping;
          laminateFloorTexture.wrapT = THREE.RepeatWrapping;
          laminateFloorTexture.repeat.set(3, 3);

          this.floorMaterial = new THREE.MeshStandardMaterial({
            map: laminateFloorTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 12,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        title: "Teppich",
        manufacturer: "",
        attributes: {
          sound: 30,
          particulates: 40,
          effluvium: 5,
          comfort: 10,
        },
        image: "/floors/Rug_005_ambientOcclusion.jpg",
        onSelect: () => {
          const textures = {
            baseColorTexture: this.textureLoader.load(
              "floors/Rug_005_basecolor.jpg",
            ),
            ambientOcclusionTexture: this.textureLoader.load(
              "floors/Rug_005_ambientOcclusion.jpg",
            ),
            heightTexture: this.textureLoader.load("floors/Rug_005_height.png"),
            normalTexture: this.textureLoader.load("floors/Rug_005_normal.jpg"),
            roughnessTexture: this.textureLoader.load(
              "floors/Rug_005_roughness.jpg",
            ),
          };

          Object.keys(textures).forEach((key) => {
            textures[key].flipY = false;
            textures[key].encoding = THREE.sRGBColorSpace;
            textures[key].wrapS = THREE.RepeatWrapping;
            textures[key].wrapT = THREE.RepeatWrapping;
            textures[key].repeat.set(28, 23);
          });

          this.floorMaterial = new THREE.MeshStandardMaterial({
            map: textures.baseColorTexture,
            aoMap: textures.ambientOcclusionTexture,
            //displacementMap: textures.heightTexture,
            normalMap: textures.normalTexture,
            roughnessMap: textures.roughnessTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 10,
            displacementScale: 0.1,
            displacementBias: -0.05,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        title: "Vinyl Fliesenoptik",
        manufacturer: "",
        attributes: {
          temperature: 10,
          particulates: 20,
          effluvium: 40,
        },
        image: "/floors/Tiles_035_basecolor.jpg",
        onSelect: () => {
          const textures = {
            baseColorTexture: this.textureLoader.load(
              "floors/Tiles_035_basecolor.jpg",
            ),
            ambientOcclusionTexture: this.textureLoader.load(
              "floors/Tiles_035_ambientOcclusion.jpg",
            ),
            heightTexture: this.textureLoader.load(
              "floors/Tiles_035_height.png",
            ),
            normalTexture: this.textureLoader.load(
              "floors/Tiles_035_normal.jpg",
            ),
            roughnessTexture: this.textureLoader.load(
              "floors/Tiles_035_roughness.jpg",
            ),
          };

          Object.keys(textures).forEach((key) => {
            textures[key].flipY = false;
            textures[key].encoding = THREE.sRGBColorSpace;
            textures[key].wrapS = THREE.RepeatWrapping;
            textures[key].wrapT = THREE.RepeatWrapping;
            textures[key].repeat.set(12, 12);
          });

          this.floorMaterial = new THREE.MeshStandardMaterial({
            color: 0xdedede,
            map: textures.baseColorTexture,
            aoMap: textures.ambientOcclusionTexture,
            //displacementMap: textures.heightTexture,
            normalMap: textures.normalTexture,
            roughnessMap: textures.roughnessTexture,
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 7,
            displacementScale: 0.1,
            displacementBias: -0.05,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor"].includes(child.name)) {
              child.material = this.floorMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
    ],
    floor_boards: [
      {
        title: "Einfache Fußleisten",
        manufacturer: "",
        attributes: {},
        image: undefined,
        onSelect: () => {
          this.floorBoardMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color(0xefefef), // 0xd6abac c2cdbc
            lightMap: this.getLightMapMaterial().map,
            lightMapIntensity: 8,
          });

          this.group.children[0].children.forEach((child) => {
            if (this.slots_meshes["floor_boards"].includes(child.name)) {
              child.material = this.floorBoardMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
    ],
    walls: [
      {
        title: "Latex: Linkwasser",
        manufacturer: "",
        attributes: { effluvium: 10 },
        image: undefined,
        color: "#c7c7d8",
        onSelect: () => {
          this.group.children[0].children.forEach((child) => {
            this.wallMaterial = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xc7c7d8), // 0xd6abac c2cdbc
              lightMap: this.getLightMapMaterial().map,
              lightMapIntensity: 5,
            });

            if (this.slots_meshes["walls"].includes(child.name)) {
              child.material = this.wallMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        title: "Latex: Muschelschale",
        manufacturer: "",
        attributes: { effluvium: 10 },
        image: undefined,
        color: "#d6abac",
        onSelect: () => {
          this.group.children[0].children.forEach((child) => {
            this.wallMaterial = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xd6abac), // c2cdbc
              lightMap: this.getLightMapMaterial().map,
              lightMapIntensity: 5,
            });

            if (this.slots_meshes["walls"].includes(child.name)) {
              child.material = this.wallMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        title: "Mineral: Paris-Grün",
        manufacturer: "",
        attributes: {},
        image: undefined,
        color: "#c2cdbc",
        onSelect: () => {
          this.group.children[0].children.forEach((child) => {
            this.wallMaterial = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xc2cdbc), //
              lightMap: this.getLightMapMaterial().map,
              lightMapIntensity: 5,
            });

            if (this.slots_meshes["walls"].includes(child.name)) {
              child.material = this.wallMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        title: "Dispersion: Gainsboro",
        manufacturer: "",
        attributes: { effluvium: 10 },
        image: undefined,
        color: "#DEDEDE",
        onSelect: () => {
          this.group.children[0].children.forEach((child) => {
            this.wallMaterial = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xdedede), //
              lightMap: this.getLightMapMaterial().map,
              lightMapIntensity: 5,
            });

            if (this.slots_meshes["walls"].includes(child.name)) {
              child.material = this.wallMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
      {
        title: "Dispersion: Weißer Rauch",
        manufacturer: "",
        attributes: { effluvium: 10 },
        image: undefined,
        color: "#EFEFEF",
        onSelect: () => {
          this.group.children[0].children.forEach((child) => {
            this.wallMaterial = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xefefef), //
              lightMap: this.getLightMapMaterial().map,
              lightMapIntensity: 5,
            });

            if (this.slots_meshes["walls"].includes(child.name)) {
              child.material = this.wallMaterial;
            }
          });
        },
        onUnselect: () => {
          // floors should just change, not be unselected
        },
      },
    ],

    interior_walls: [
      {
        title: "Massiv: Kalksandstein mit Putz",
        manufacturer: "",
        attributes: {
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "Massiv: H+H Porenbeton mit Putz",
        manufacturer: "",
        attributes: {
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "Einfache Trockenbauwand",
        manufacturer: "",
        attributes: {
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "OSB-Beplankung mit Gipsfaserplatte",
        manufacturer: "",
        attributes: {
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
    ],
    sloping_roof: [
      {
        title: "Fermacell",
        manufacturer: "",
        attributes: {
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "Ungeprüfte Gipsfaserplatten",
        manufacturer: "",
        attributes: {
          effluvium: 10,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
    ],
    heating: [
      {
        title: "Wärmepumpe ohne Kühlfunktion",
        manufacturer: "Nibe",
        attributes: {
          comfort: 5,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "Wärmepumpe mit Kühlfunktion",
        manufacturer: "Nibe",
        attributes: {
          comfort: 10,
          temperature: -3,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "Pelletheizung",
        manufacturer: "",
        attributes: {
          comfort: -10,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
    ],
    ventilation: [
      {
        title: "Reine Fensterlüftung",
        manufacturer: "",
        attributes: {
          temperature: 3,
          humidity: -15,
          particulates: 20,
          effluvium: -5,
          sound: -10,
          comfort: -20,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "Lüftungsanlage kombiniert mit Wärmepumpe",
        manufacturer: "",
        attributes: {
          temperature: -0.5,
          humidity: -20,
          particulates: -10,
          effluvium: -10,
          comfort: 10,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
      {
        title: "Lüftungsanlage nicht kombiniert mit Wärmepumpe",
        manufacturer: "",
        attributes: {
          humidity: -40,
          particulates: -10,
          effluvium: -10,
          sound: 5,
          comfort: 5,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {},
        onUnselect: () => {},
      },
    ],
    smart_home: [
      {
        title: "Smart-Home: WLAN",
        manufacturer: "",
        attributes: {
          comfort: 10,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {
        },
        onUnselect: () => {
          delete this.slots_components["smart_home"];
        },
      },
      {
        title: "Smart-Home: Kabelgebunden",
        manufacturer: "",
        attributes: {
          comfort: 20,
        },
        image: undefined,
        color: "#a28df3",
        onSelect: () => {
        },
        onUnselect: () => {
          delete this.slots_components["smart_home"];
        },
      },
    ],
    app: [
      {
        title: "Mittelstädt Bauherren-App",
        manufacturer: "Mittelstädt",
        attributes: {
          comfort: 10,
        },
        image: undefined,
        color: "#f87171",
        onSelect: () => {
        },
        onUnselect: () => {
          delete this.slots_components["app"];
        },
      }
    ],
    solar: [
      {
        title: "PV-Anlage",
        manufacturer: "Mittelstädt",
        attributes: {
          comfort: 10,
        },
        image: undefined,
        color: "#fcd34d",
        onSelect: () => {
        },
        onUnselect: () => {
          delete this.slots_components["app"];
        },
      }
    ],
  };

  constructor() {
    super();
    this.loadMaterials();
    window.addEventListener("room:loaded", () => {
      this.applySlotMaterials();
    });
    this.load();
  }

  applySlotMaterials() {
    Object.keys(this.slots_components).forEach((slot) => {
      let component_index = this.slots_components[slot];
      let component = this.getComponent(slot, component_index);
      if (component) component.onSelect();
    });

    window.dispatchEvent(
      new CustomEvent("attributes:change", {
        detail: this.getAttributes(),
      }),
    );
  }

  getLightMapMaterial(mask) {
    mask = mask || "";
    let leftLightStatus = !!(
      this.slots_components.window_left >= 0 &&
      this.slots_components.window_left_blinds === undefined
    )
      ? "1"
      : "0";
    let rightLightStatus = !!(
      this.slots_components.window_right >= 0 &&
      this.slots_components.window_right_blinds === undefined
    )
      ? "1"
      : "0";
    leftLightStatus = mask[0] && mask[0] !== "?" ? mask[0] : leftLightStatus;
    rightLightStatus = mask[1] && mask[1] !== "?" ? mask[1] : rightLightStatus;
    const lightBitmap = leftLightStatus + rightLightStatus;

    let lightMap;

    switch (lightBitmap) {
      case "11":
        lightMap = this.materials.one_one;
        break;
      case "01":
        lightMap = this.materials.zero_one;
        break;
      case "10":
        lightMap = this.materials.one_zero;
        break;
      case "00":
        lightMap = this.materials.zero_zero;
        break;
      default:
        lightMap = this.materials.one_one;
    }

    return lightMap;
  }

  load() {
    this.gltfLoader.load(this.config.model, (gltf) => {
      gltf.scene.traverse((child) => {
        if (this.config.debug) console.log("loading child", child.name);
        if (this.slots_meshes["floor"].includes(child.name)) {
          window.floor = child;
        }

        // Fix y-position of the poster (distance from the wall),
        // so it doesn't compete with the wall for the lightmap
        if (child.name === "Poster") {
          child.translateY(0.005);
        }

        child.material = this.getLightMapMaterial();
        child.material.side = THREE.DoubleSide;

        child.visible = !this.config.hidden_meshes_on_load.includes(child.name);
      });
      this.group.add(gltf.scene);

      window.dispatchEvent(new CustomEvent("room:loaded"));
    });
  }
}
